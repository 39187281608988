import React from 'react'
import PropTypes from 'prop-types'
import BackgroundVideo from './components/BackgroundVideo'
import VideoDefault from './components/VideoDefault'
import VideoSimple from './components/VideoSimple'
import ExternalVideo from './components/ExternalVideo'
import './../../../node_modules/video.js/dist/video-js.css'

const Video = ({ bgVideo, size, externalSource, ...rest }) => {
  if (bgVideo) return <BackgroundVideo {...rest} />

  const VideoType = externalSource ? ExternalVideo : VideoSimple

  switch (size) {
    case 'Small':
      return (
        <VideoType
          setWidth="50%"
          size={size}
          externalSource={externalSource}
          {...rest}
        />
      )

    case 'Full Bleed':
      return (
        <VideoType
          setWidth="100%"
          size={size}
          externalSource={externalSource}
          {...rest}
        />
      )
    default:
      return (
        <VideoType
          setWidth="100%"
          size="Full Width"
          externalSource={externalSource}
          {...rest}
        />
      )
  }
}

Video.propTypes = {
  bgVideo: PropTypes.bool,
  size: PropTypes.string,
}

export default Video
