import React from 'react'
import PropTypes from 'prop-types'
import ImageSimple from './components/ImageSimple'

const Image = ({ size, ...rest }) => {
  switch (size) {
    case 'Regular':
      return <ImageSimple width="50%" size={size} {...rest} />
    case 'Full':
      return <ImageSimple width="100vw" size={size} {...rest} />
    case 'Large':
    default:
      return <ImageSimple width="100%" size={size} {...rest} />
  }
}

Image.propTypes = {
  size: PropTypes.string,
  style: PropTypes.object,
}

export default Image
