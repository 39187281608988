import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Image from '@components/Image'
import Box from '@components/Box'
import {
  StyledPlayButton,
  StyledPlayButtonContainer,
  VideoContainerFullWidth,
  VideoWrapper,
} from '../styles'
import playBtn from '../assets/Playbutton.png'
import { useInView } from 'react-intersection-observer'

const IframeVideoWrapper = styled.div`
  position: relative;
  padding-bottom: min(56.25%); /* 16:9 */

  height: 0;
  width: 100%;
  background-color: black;
  ${({ notInteractable }) => notInteractable && 'z-index: -900;'}

  & iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`
const IFrame = React.forwardRef(
  ({ src, muted = 1, loop = 0, controls = 1, notInteractable }, ref) => {
    // need to use video id as playlist value for loop to work
    const id = src?.split('embed/')?.[1]?.split('?')?.[0]

    return (
      <IframeVideoWrapper notInteractable={notInteractable}>
        <iframe
          ref={ref}
          id="youtube_player"
          aria-label="external video"
          tabIndex="0"
          src={`${src}?enablejsapi=1&autoplay=1&mute=${muted}&loop=${loop}&playlist=${id}&controls=${controls}`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          muted
        />
        <div id="iframe" tabIndex="0" />
      </IframeVideoWrapper>
    )
  }
)

IFrame.displayName = 'IFrame'

const ExternalVideo = ({
  externalSource,
  videoBehavior,
  thumbnail,
  setWidth,
  size,
  notInteractable = false,
  togglePlayOverride = null,
  stopPlayer,
  muteOverride = null,
  ...rest
}) => {
  const muted =
    muteOverride !== null
      ? muteOverride
      : videoBehavior === 'Looping w/o sound' ||
        videoBehavior === 'Autoplay w/o sound'
  const autoplay =
    videoBehavior === 'Looping w/o sound' ||
    videoBehavior === 'Autoplay w/o sound'

  const loop = videoBehavior === 'Looping w/o sound'
  const Wrapper = size === 'Full Bleed' ? VideoContainerFullWidth : VideoWrapper

  const [showThumbnail, setShowThumbnail] = useState(!autoplay)
  const [inViewRef, inView, entry] = useInView({ threshold: 0.2 })
  const iframeRef = useRef()

  const pause = () => {
    if (iframeRef?.current?.contentWindow) {
      iframeRef.current.contentWindow.postMessage(
        '{"event":"command","func":"pauseVideo","args":""}',
        '*'
      )
    }
  }

  const play = () => {
    if (iframeRef?.current?.contentWindow) {
      iframeRef.current.contentWindow.postMessage(
        '{"event":"command","func":"playVideo","args":""}',
        '*'
      )
    }
  }

  useEffect(() => {
    if (togglePlayOverride !== null) {
      if (togglePlayOverride) {
        play()
      } else if (!togglePlayOverride) {
        pause()
      }
    }
  }, [togglePlayOverride])

  useEffect(() => {
    if (togglePlayOverride === null && videoBehavior !== 'Regular') {
      if (!inView) {
        pause()
      }
      if (inView) {
        play()
      }
    }
  }, [inView, togglePlayOverride, videoBehavior])

  useEffect(() => {
    if (stopPlayer) {
      setTimeout(() => pause(), 1000)
    }
  }, [stopPlayer])

  return (
    <Wrapper
      ref={inViewRef}
      width={size === 'Full Bleed' ? '100vw' : setWidth}
      mx={size === 'Small' && 'auto'}
    >
      {showThumbnail ? (
        <Box cursor="pointer" position="relative">
          <Image
            src={thumbnail}
            onClick={() => setShowThumbnail(false)}
            maxWidth="100%"
          />
          <StyledPlayButtonContainer
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
            role="button"
            height="79px"
            width="79px"
          >
            <StyledPlayButton
              src={playBtn}
              alt="play-button"
              onClick={() => setShowThumbnail(false)}
            />
          </StyledPlayButtonContainer>
        </Box>
      ) : (
        <>
          <IFrame
            ref={iframeRef}
            notInteractable={notInteractable}
            src={externalSource}
            muted={muted ? 1 : 0}
            loop={loop ? 1 : 0}
            controls={loop ? 0 : 1}
          />
        </>
      )}
    </Wrapper>
  )
}

ExternalVideo.propTypes = {}

export default ExternalVideo
