import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'
import theme from '@theme'

import {
  position,
  flexbox,
  space,
  color,
  layout,
  typography,
  system,
} from 'styled-system'

const textTransform = system({
  textTransform: { property: 'textTransform' },
})

export const StyledImage = styled(GatsbyImage)`
  ${layout}
`

export const StyledGatsbyImage = styled(GatsbyImage)`
  ${space}
  ${layout}
  
  ${({ aspectRatio }) => aspectRatio && `aspect-ratio: ${aspectRatio};`}
`

export const StyledVanillaImage = styled.img`
  overflow: visible;
  ${({ withShadow }) =>
    withShadow ? `box-shadow: ${theme.shadows.bg};` : `box-shadow: none;`}
  ${space}
  ${layout}

  ${({ aspectRatio }) => aspectRatio && `aspect-ratio: ${aspectRatio};`}
`

export const StyledImageContainer = styled.div`
  height: 100%;
  ${space}
  ${position}
  ${layout}
  ${color}
  ${flexbox}
`

export const StyledFullBleedContainer = styled.div`
  display: flex;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;

  ${space}
  ${position}
  ${layout}
  ${color}
  ${flexbox}
`

export const StyledCaption = styled.div`
  ${color}
  ${layout}
  ${position}
  ${space}
  ${flexbox}
  ${typography}
  ${textTransform}
`

export const StyledText = styled.p`
  ${color}
  ${layout}
  ${position}
  ${space}
  ${flexbox}
  ${typography}
  ${textTransform}
`
