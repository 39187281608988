import React, { useState } from 'react'
import { Container } from '../styles'
import playBtn from '@assets/icon_play.svg'
import pauseBtn from '@assets/icon_pause.svg'

const BackgroundPauseBtn = ({ toggle, handleKeyDown, isPlaying }, ref) => {
  return (
    <Container
      ref={ref}
      zIndex={1000}
      position="absolute"
      bottom="3%"
      left="0px"
      right="0px"
      width="100%"
      margin="0 auto"
      maxWidth={[
        'maxWidth.xsmall',
        'maxWidth.small',
        'maxWidth.medium',
        'maxWidth.large',
        'maxWidth.xlarge',
        'maxWidth.xxlarge',
      ]}
      display="flex"
      justifyContent="flex-end"
    >
      <Container
        position="relative"
        zIndex={100}
        cursor="pointer"
        width="fit-content"
      >
        {isPlaying ? (
          <img
            src={pauseBtn}
            alt="pause-button"
            aria-label="pause-button"
            role="button"
            tabIndex={0}
            onClick={() => toggle('pause')}
            onKeyDown={e => handleKeyDown(e, 'pause')}
          />
        ) : (
          <img
            src={playBtn}
            alt="play-button"
            aria-label="play-button"
            role="button"
            tabIndex={0}
            onClick={() => toggle('play')}
            onKeyDown={e => handleKeyDown(e, 'play')}
          />
        )}
      </Container>
    </Container>
  )
}

export default React.forwardRef(BackgroundPauseBtn)
