import Image from '@components/Image'
import styled, { keyframes } from 'styled-components'
import {
  space,
  color,
  layout,
  typography,
  border,
  flexbox,
  position,
  background,
  system,
} from 'styled-system'
import theme from '@theme'
import playButton from './assets/Playbutton.png'
import Box from '@components/Box'

const textTransform = system({
  textTransform: { property: 'textTransform' },
})

const pulse = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(.8); }
  100% { transform: scale(1); }
  
`
export const StyledPlayButton = styled(Image)`
  padding: 0;
  margin: 0;
  ${space}
  ${color}
  ${typography}
  ${position}
  ${border}
  ${layout}
  ${flexbox}

`

export const StyledPlayButtonContainer = styled(Box)`
  &:hover ${StyledPlayButton} {
    animation: ${pulse} 1s infinite ease-in-out;
  }
`

export const StyledVideoOverlay = styled.div`
${position}
${space}
${layout}
${border}
${typography}
${color}
${background}
${flexbox}
`

export const TextContainer = styled.div`
  ${space}
  ${layout}
  ${border}
  ${typography}
  ${color}
  ${flexbox}
  display: flex;
  align-items: center;
  box-sizing: border-box;
  height: 40vh;
  max-width: 998px;
  padding: 20px;
  z-index: 1;
  font-family: sans-serif;
  position: relative;
`

export const FadeInText = styled.div`
  ${space}
  ${layout}
  ${border}
  ${typography}
  ${color}
  ${flexbox}
`

export const FadeOutText = styled.div`
  ${space}
  ${layout}
  ${border}
  ${typography}
  ${color}
  ${flexbox}
  z-index: 1;
  top: 30%;
  position: sticky;
  transition: opacity 600ms ease-out, transform 600ms ease-out, visibility 600ms ease-out;
`

export const H3 = styled.h3`
  ${color}
  ${layout}
  ${typography}
  ${space}
  position: relative;
  z-index: 1;
`

export const StyledVideo = styled.video`
  position: relative;
  width: 100%;
  ${color}
  ${layout}
  ${typography}
  ${space}
`
export const StyledImage = styled(Image)`
  ${space}
  ${layout}
  ${border}
  ${typography}
  ${color}
  ${flexbox}
  ${position}
`

export const Container = styled.div`
  box-sizing: border-box;
  transition: opacity 0.6s ease;
  ${color}
  ${layout}
  ${typography}
  ${space}
  ${position}
  ${flexbox}
  ${({ cursor }) => cursor && `cursor: ${cursor};`}
`

export const VideoContainer = styled.div`
  box-sizing: border-box;


  .video-js {
    background-color: ${
      process.env.GATSBY_SPACECAMP ? 'black' : 'white'
    } !important;
  }

  ${({ withShadow }) =>
    withShadow ? `box-shadow: ${theme.shadows.bg};` : `box-shadow: none;`}
  .video-js {
    width: 100%;
    height: 100%;
    min-height: inherit;
    z-index: 0;
    cursor: pointer;

    position: relative !important;
    &:hover .vjs-big-play-button {
      background: none !important;
    }

  .vjs-tech {
    ${({ videoFit }) =>
      videoFit === 'Cover'
        ? 'object-fit: cover;'
        : videoFit === 'Contain'
        ? 'object-fit: contain; object-position: center top;'
        : ''}
    }
  }

  .video-js.landscape {
    padding-top: 56.25%;
  }



  .vjs-tech {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    &:focus {
      outline: 0;
    }
  }

  .vjs-big-play-button {
    z-index: 3 !important;
    background-color: transparent !important;
    border-radius: 50% !important;
    border: 0 !important;
    background: transparent;
    &:hover {
      background: none !important;
    }
    &:focus {
      background: none !important;
    }
  }

  .video-js .vjs-big-play-button .vjs-icon-placeholder:before {
    content: '';
    background-image: url(${playButton});
    background-repeat: no-repeat;
    background-size: 46px;
    background-position: 55% calc(50% - 0px);
    border: none !important;
    box-shadow: none !important;
  }

  .vjs-fluid {
  
    ${({ fitContainer }) =>
      fitContainer && 'padding-top: 0 !important; height: 100% !important;'}
   
  }
  ${space}
  ${layout}
  ${border}
  ${typography}
  ${color}
  ${flexbox}
  ${position}
`

export const VideoContainerFullWidth = styled.div`
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  ${space}
  ${layout}
  ${border}
  ${typography}
  ${color}
  ${flexbox}
  ${position}
`

export const VideoWrapper = styled.div`
  position: relative;
  ${space}
  ${position}
  ${layout}
  ${color}
  ${flexbox}
`
export const StyledCaption = styled.div`
  ${color}
  ${layout}
  ${position}
  ${space}
  ${flexbox}
  ${typography}
  ${textTransform}
`

export const StyledText = styled.p`
  ${color}
  ${layout}
  ${position}
  ${space}
  ${flexbox}
  ${typography}
  ${textTransform}
`
export const Video = styled.video`
  ${color}
  ${layout}
  ${typography}
  ${space}
  cursor: ${({ cursor = 'default' }) => cursor} !important;
`
