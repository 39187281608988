import React, { useRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  StyledVideo,
  Container,
  VideoContainer,
  StyledImage,
  StyledVideoOverlay,
} from '../styles'
import { useMobile, useBrowser } from '@hooks'
import { useVideoPlayer, useOnScreen } from '@hooks/'
import BackgroundPauseBtn from './BackgroundPauseBtn'
import { useInView } from 'react-intersection-observer'

const VideoWithOptionalScroll = ({
  title = 'bg video',
  sourceLandscape,
  sourcePortrait,
  id,
  posterLandscape,
  posterPortrait,
  videoBehavior = 'Regular',
  children,
  aspectRatio = '16:9',
  gradient,
}) => {
  const { browser } = useBrowser()
  const [isMobile] = useMobile()
  const [pauseOverride, setPauseOverride] = useState(false)
  const [isPlaying, setIsPlaying] = useState(true)

  const [inViewRef, inView, entry] = useInView({
    threshold: 0.2,
  })
  videoBehavior = videoBehavior || 'Regular'

  const videoSrc = isMobile && sourcePortrait ? sourcePortrait : sourceLandscape
  const thumbnail =
    isMobile && sourcePortrait ? posterPortrait : posterLandscape
  const imageSrc = thumbnail?.src
  const imageAlt = thumbnail?.alt

  const videoFit = 'Cover'

  const muted =
    videoBehavior === 'Looping w/o sound' ||
    videoBehavior === 'Autoplay w/o sound'
  const autoplay =
    videoBehavior === 'Looping w/o sound' ||
    videoBehavior === 'Autoplay w/o sound'
  const loop = videoBehavior === 'Looping w/o sound'

  const containerRef = useRef()
  const imageRef = useRef()
  const pauseBtnRef = useRef()
  const childRef = useRef()

  const { videoRef, player } = useVideoPlayer({
    src: videoSrc,
    thumbnail,
    loop: loop,
    autoplay: autoplay,
    muted: muted,
    hideControls: true,
  })
  const visible = useOnScreen(videoRef)

  const toggle = action => {
    if (action === 'play') {
      player.play()
      setPauseOverride(false)
    } else {
      player.pause()
      setPauseOverride(true)
    }
    setIsPlaying(isPlaying => !isPlaying)
  }

  const handleKeyDown = (e, action) => {
    if (e.key === 'Enter') {
      toggle(action)
    }
  }

  useEffect(() => {
    if (player && visible && autoplay && !pauseOverride && player.paused()) {
      player.play()
    }
  }, [autoplay, browser, isMobile, loop, pauseOverride, player, visible])

  // for mobile to desktop src switch
  useEffect(() => {
    if (videoRef?.current && player && player?.currentSrc() !== videoSrc) {
      player.src({ type: 'video/mp4', src: videoSrc })
      videoRef.current.load()
    }
  }, [videoSrc, player, videoRef])

  useEffect(() => {
    if (videoBehavior === 'Loop 1x and Display Poster') {
      videoRef.current.addEventListener(
        'ended',
        () => {
          if (videoRef.current) {
            videoRef.current.style.opacity = 0
          }
          if (imageRef.current) {
            imageRef.current.style.visibility = 'visible'
            imageRef.current.style.opacity = 1
            imageRef.current.style.zIndex = 0
          }
          if (pauseBtnRef.current) {
            pauseBtnRef.current.style.opacity = 0
            pauseBtnRef.current.style.visibility = 'hidden'
          }
        },
        false
      )
    }
  }, [videoBehavior, videoRef])

  useEffect(() => {
    if (player && inView && !pauseOverride) {
      player.play()

      setIsPlaying(true)
    }
    if (player && !inView) {
      player.pause()

      setIsPlaying(false)
    }
  }, [inView, pauseOverride, player])

  return (
    <Container ref={inViewRef} minHeight="56.25vw">
      <VideoContainer
        zIndex="-1"
        id="container"
        ref={containerRef}
        width="100%"
        videoFit={videoFit}
        top="0"
        left="0"
        bottom="0"
        right="0"
        position="absolute"
      >
        <div data-vjs-player>
          <StyledVideo
            id={id}
            ref={videoRef}
            className="video-js vjs-big-play-centered landscape"
            preload="metadata"
            muted
            aspectRatio={aspectRatio}
            webkit-playsinline={videoSrc ? 1 : 0}
            playsInline={videoSrc ? 1 : 0}
            aria-label={title}
          >
            <source src={videoSrc} type="video/mp4" />
            Your browser does not support the video tag.
          </StyledVideo>
        </div>
        <Container
          ref={imageRef}
          width="100%"
          height="100%"
          position="absolute"
          top="0"
          opacity="0"
          zIndex="-10"
        >
          <StyledImage
            src={imageSrc}
            alt={imageAlt}
            width="100%"
            height="100%"
          />
        </Container>
        <StyledVideoOverlay
          top="0"
          left="0"
          bottom="0"
          right="0"
          position="absolute"
          backgroundImage={gradient}
        />
      </VideoContainer>
      <BackgroundPauseBtn
        toggle={toggle}
        handleKeyDown={handleKeyDown}
        isPlaying={isPlaying}
        childRef={childRef}
        ref={pauseBtnRef}
        player={player}
        setPauseOverride={setPauseOverride}
        videoFit={videoFit}
      />
      <Container height="100%" ref={childRef}>
        {children}
      </Container>
    </Container>
  )
}
export default VideoWithOptionalScroll

VideoWithOptionalScroll.propTypes = {
  video: PropTypes.shape({ assetId: PropTypes.string, src: PropTypes.string }),
}
