import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  VideoContainer,
  VideoWrapper,
  VideoContainerFullWidth,
  Video,
} from '../styles'
import { useVideoPlayer, useLocale } from '@hooks'
import { langObj } from '@utils'
import VideoCaption from './VideoCaption'
import { useInView } from 'react-intersection-observer'
import '../../../../node_modules/video.js/dist/video-js.css'

const VideoSimple = ({
  title = 'video',
  id = '',
  src = '',
  thumbnail = '',
  captions = {},
  modal,
  setWidth = '100%',
  size = '',
  caption = '',
  theme = 'light',
  displayCaption = false,
  withShadow = false,
  videoBehavior = 'Regular',
  stopPlayer,
  pausePlayer,
  className,
  togglePlayOverride = null,
  cursor = 'default',
  videoFit,
  height = 'auto',
  muteOverride = null,
  ...rest
}) => {

  videoBehavior = videoBehavior || 'Regular'
  const muted =
    muteOverride !== null
      ? muteOverride
      : videoBehavior === 'Looping w/o sound' ||
        videoBehavior === 'Autoplay w/o sound'
  const autoplay =
    videoBehavior === 'Looping w/o sound' ||
    videoBehavior === 'Autoplay w/o sound'
  const loop = videoBehavior === 'Looping w/o sound'

  const [inViewRef, inView, entry] = useInView({ threshold: 0.2 })

  const { videoRef, player } = useVideoPlayer({
    src,
    thumbnail,
    modal,
    loop: loop,
    autoplay: autoplay,
    muted: muted,
    hideControls: loop,
    showControls: !loop,
  })

  const { locale } = useLocale()
  const handleSrcUrl = src => {
    let newSrc =
      src &&
      typeof src !== 'object' &&
      (!src.includes('http') || !src.includes('https'))
        ? `https:${src}`
        : src

    return newSrc
  }

  useEffect(() => {
    if (player && togglePlayOverride !== null) {
      if (togglePlayOverride && player.paused()) {
        player.play()
      } else if (!togglePlayOverride && !player.paused()) {
        player.pause()
      }
    }
  }, [player, togglePlayOverride])

  useEffect(() => {
    if (player && togglePlayOverride === null && videoBehavior !== 'Regular') {
      if (player && !player.paused() && !inView) {
        player.pause()
      }
      if (player && player.paused() && inView) {
        player.play()
      }
    }
  }, [inView, player, togglePlayOverride, videoBehavior])

  // work-around for lightbox thumbnail pause on swipe
  useEffect(() => {
    if (player && stopPlayer) {
      setTimeout(() => !player.paused() && player.pause(), 1000)
    }
  }, [player, stopPlayer])

  // for mobile to desktop src switch

  useEffect(() => {
    if (videoRef?.current && player && player?.currentSrc() !== src) {
      player.src({ type: 'video/mp4', src: src })
      videoRef.current.load()
    }
  }, [src, player, videoRef])

  src = handleSrcUrl(src)
  captions = handleSrcUrl(captions?.file?.url)

  const VideoToRender = (
    <>
      <VideoContainer
        theme={`${theme}.bg.primary`}
        width={setWidth}
        withShadow={withShadow}
        videoFit={videoFit}
        height={height}
        ref={inViewRef}
      >
        <div data-vjs-player>
          <Video
            id={id}
            cursor={cursor}
            ref={videoRef}
            className={`video-js vjs-big-play-centered ${className}`}
            preload="metadata"
            webkit-playsinline={src ? 1 : 0}
            playsInline={src ? 1 : 0}
            role="video"
            aria-label={title}
          >
            {captions && (
              <track
                src={captions}
                srcLang={locale}
                label={langObj[locale] || 'English'}
              />
            )}
            <source src={src} type="video/mp4" />
            Your browser does not support the video tag.
          </Video>
        </div>
      </VideoContainer>
      {displayCaption && (
        <VideoCaption caption={caption} setWidth={setWidth} theme={theme} />
      )}
    </>
  )

  return size === 'Full Bleed' ? (
    <VideoContainerFullWidth
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      {VideoToRender}
    </VideoContainerFullWidth>
  ) : (
    <VideoWrapper
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      height={height}
      width="100%"
    >
      {VideoToRender}
    </VideoWrapper>
  )
}

VideoSimple.propTypes = {
  title: PropTypes.string,
  id: PropTypes.string,
  src: PropTypes.string,
  thumbnail: PropTypes.string,
  captions: PropTypes.object,
  setWidth: PropTypes.string,
  size: PropTypes.string,
  caption: PropTypes.string,
  theme: PropTypes.string,
  displayCaption: PropTypes.bool,
  withShadow: PropTypes.bool,
}

export default VideoSimple
