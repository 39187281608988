import React from 'react'
import PropTypes from 'prop-types'
import {
  StyledVanillaImage,
  StyledImageContainer,
  StyledFullBleedContainer,
  StyledGatsbyImage,
} from '../styles'
import ImageCaption from './ImageCaption'

const ImageSimple = ({
  src,
  alt = '',
  width = '100%',
  height = '100%',
  caption,
  size,
  theme = 'light',
  withCaption = false,
  withShadow = false,
  aspectRatio,
  ...rest
}) => {
  if (!src) return null
  const isUrl = typeof src === 'string'

  const Wrapper =
    size === 'Full' ? StyledFullBleedContainer : StyledImageContainer

  return (
    <Wrapper width={width} mx={size === 'Regular' && 'auto'}>
      {isUrl ? (
        <StyledVanillaImage
          src={src}
          width="100%"
          aria-label={caption}
          height={height}
          objectFit="cover"
          alt={caption ? '' : alt}
          withShadow={withShadow}
          {...rest}
        />
      ) : (
        <StyledGatsbyImage
          image={src}
          alt={caption ? '' : alt}
          aspectRatio={aspectRatio}
          {...rest}
        />
      )}
      {withCaption && (
        <ImageCaption caption={caption} width={width} theme={theme} />
      )}
    </Wrapper>
  )
}

ImageSimple.propTypes = {
  alt: PropTypes.string,
  src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  width: PropTypes.string,
  setWidth: PropTypes.string,
  height: PropTypes.string,
  caption: PropTypes.string,
  altDescription: PropTypes.string,
  credit: PropTypes.string,
  preview: PropTypes.bool,
  size: PropTypes.string,
  theme: PropTypes.string,
  withCaption: PropTypes.bool,
  withShadow: PropTypes.bool,
}

export default ImageSimple
