import React from 'react'
import PropTypes from 'prop-types'
import { StyledCaption, StyledText } from '../styles'

const ImageCaption = ({ width = '100%', caption, theme = 'light' }) => {
  if (!caption) return null
  return (
    <StyledCaption
      display="flex"
      width={width}
      justifyContent="start"
      textTransform="uppercase"
    >
      <StyledText
        mt={3}
        fontSize={1}
        color={`${theme}.peripheralText`}
        fontWeight="300"
        letterSpacing="1px"
        lineHeight={2}
      >
        {caption}
      </StyledText>
    </StyledCaption>
  )
}

ImageCaption.propTypes = {
  setWidth: PropTypes.string,
  caption: PropTypes.string,
  theme: PropTypes.string,
}

export default ImageCaption
